<template>
  <div class="dynamicList">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">动态</div>
      <div class="rightBtn"></div>
    </div>
    <div class="dynamicMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="dynamicItem" v-for="item in list" :key="item.id">
          <div class="dynamicInfo">
            <svg-icon class="dynamicIcon" :iconClass="item.msgType"></svg-icon>
            <router-link tag="div" :to="`/userHomePage?uid=${item.sendUid}`" class="userBox">
              <ImgDecypt class="avatar" :src="item.sendAvatar" round />
              <Vlogo :vid="item.id" class="vlogo" v-if="item.superUser" />
              <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="item.merchantUser == 1" />
            </router-link>
            <router-link :to="`/userHomePage?uid=${item.sendUid}`" class="userName">{{ item.sendName }}</router-link>
            <div class="desc">{{ item.content }}</div>
          </div>
          <div class="horizontalLine"></div>
          <div class="dynamicContent" v-if="item.objName" @click="goRouter(item)">
            {{ item.objName }}
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import PullRefresh from '@/components/PullRefresh';
import { queryDynamicList } from '@/api/mine';
export default {
  name: 'DynamicList',
  components: {
    Vlogo,
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryDynamicList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 校验是否会员、会员时间是否到期
    checkVip(item) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(item.vipExpireDate).getTime();
      if (item && item.vipLevel > 0 && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    jumpDetails(item) {
      if (item.msgType == 'desire_msg') {
        let data = window.btoa(window.encodeURI(JSON.stringify({ id: item.objId })));
        this.$router.push({ path: '/questionDetails', query: { data: data } });
      } else {
        this.$router.push({ path: '/postDetails', query: { id: item.objId } });
      }
    },
    goRouter(item) {
      this.$router.push({
        path: '/postDetails',
        query: {
          id: item.objId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamicList {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      text-align: center;
    }
  }
  .dynamicMain {
    height: calc(100% - 44px);
    padding: 8px 12px;
    .dynamicItem {
      margin-bottom: 8px;
      border-radius: 4px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .dynamicInfo {
        display: flex;
        height: 49px;
        align-items: center;
        .dynamicIcon {
          height: 24px;
          width: 24px;
          margin: 0 10px;
        }
        .userBox {
          position: relative;
          .avatar {
            height: 33px;
            width: 33px;
          }
          .vlogo {
            width: 12px;
            height: 12px;
            position: absolute;
            right: -3px;
            bottom: 0;
          }
          .certificaBorder {
            position: absolute;
            top: -10px;
            left: 0px;
            width: 100%;
            font-size: 25px;
            z-index: 1;
          }
        }
        .userName {
          font-size: 16px;
          font-weight: 800;
          margin: 0px 4px 0px 8px;
        }
        .desc {
          font-size: 16px;
          color: rgb(102, 102, 102);
        }
      }
      .horizontalLine {
        height: 1px;
        background-color: rgb(246, 246, 246);
      }
      .dynamicContent {
        padding: 11px 15px 11px 44px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgb(102, 102, 102);
      }
    }
  }
}
</style>
